const truncateQueryParams = (url) => {
  url = url || "";
  return url.split("?")[0];
};

const getQueryStringAsHash = (location) => {
  let queryString = location.search;
  if (queryString === "") return {};
  queryString = queryString.replace("?", "");

  let queryStringArr = queryString.split("&");
  return queryStringArr.reduce((qsObj, qs) => {
    let qsArr = qs.split("=");
    let key = qsArr[0];
    let value = qsArr[1];
    qsObj[key] = value;
    return qsObj;
  }, {});
};

const getQueryParamValue = (location, param) => {
  const queryHash = getQueryStringAsHash(location);
  return queryHash[param];
};

const normalizeUrl = (url) => {
  url = url || "";
  return url[url.length - 1] === "/" ? url : url + "/";
};

const stripTrailingSlash = (url) => {
  if (!url) return url;
  return url.endsWith("/") ? url.slice(0, -1) : url;
};

const getStringKeyForUrl = (url) => {
  let res = url.replace(/\/$/, ""); // strip ending slash
  res = res.replace(/^\//, ""); // strip opening slash
  res = res.replace(/\//g, "_"); // replace slashes with underscores

  return res;
};

const sameUrls = (url1, url2) => {
  return normalizeUrl(url1).toLowerCase() === normalizeUrl(url2).toLowerCase();
};

const isSameUrlWithoutQueryParams = (url1, url2) => {
  const cleanUrl1 = truncateQueryParams(url1);
  const cleanUrl2 = truncateQueryParams(url2);
  return sameUrls(cleanUrl1, cleanUrl2);
};

const isExactMatchToOneOfPages = (pathName, pagesArray) => {
  const pathWithoutParams = truncateQueryParams(pathName);
  const relevantPages = pagesArray.filter((pageUrl) => sameUrls(pathWithoutParams, pageUrl));
  return relevantPages.length > 0;
};

const addParamToURL = (url, paramName, paramValue) => {
  const paramsExist = url.indexOf("?") > -1;
  const queryCharacter = paramsExist ? "&" : "?";
  return `${url}${queryCharacter}${paramName}=${paramValue}`;
};

const addHashToURL = (url, hashName) => {
  return `${url}#${hashName}`;
};

// Url for canonical path should be of type - https://path_without_params/
const normalizeUrlForCanonical = (url) => {
  url = url || "";
  url = truncateQueryParams(url);

  if (url.endsWith("/")) {
    url = url.substring(0, url.length - 1);
  }

  if (url == "" || url.startsWith("/")) {
    url = `https://monday.com${url}`;
    return url;
  }

  if (url.startsWith("https")) {
    return url;
  }

  if (url.startsWith("http")) {
    url = url.replace("http", "https");
    return url;
  }

  return url;
};

const getDecodedParamFromQuery = (location, urlParamName) => {
  const queryString = getQueryStringAsHash(location);

  return queryString[urlParamName] ? decodeURI(queryString[urlParamName]) : "";
};

const getLastPartOfUrl = (url) => {
  if (!url) return "";

  const urlWithoutParams = truncateQueryParams(url);
  const lastSlashIndex = stripTrailingSlash(urlWithoutParams).lastIndexOf("/");
  if (lastSlashIndex) {
    return urlWithoutParams.substring(lastSlashIndex + 1);
  }
  return urlWithoutParams;
};

const getUrlParams = (url) => {
  const searchParams = url?.split(/\?/)[1];
  return "?" + searchParams;
};

const getUrlParamValue = (url, param) => {
  try {
    const urlParamsString = getUrlParams(url);
    const urlParams = new URLSearchParams(urlParamsString);
    return urlParams.get(param);
  } catch {
    return "";
  }
};

const getUrlParamValueFromCurrentLocation = (param) => {
  try {
    return getDecodedParamFromQuery(window.location, param);
  } catch {
    return "";
  }
};

module.exports = {
  truncateQueryParams,
  getQueryStringAsHash,
  getQueryParamValue,
  normalizeUrl,
  stripTrailingSlash,
  sameUrls,
  isSameUrlWithoutQueryParams,
  isExactMatchToOneOfPages,
  addParamToURL,
  addHashToURL,
  normalizeUrlForCanonical,
  getDecodedParamFromQuery,
  getStringKeyForUrl,
  getLastPartOfUrl,
  getUrlParamValue,
  getUrlParamValueFromCurrentLocation
};
