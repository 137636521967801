const { COOKIE_CATEGORIES } = require("./cookies-categories");
const { StoriesCookies, UserCampaignType } = require("../services/community/constants-service");
const { USER_SELECTED_LOCALE_ID_COOKIE } = require("./localization");
const { PRICE_PER_USER_AB_TEST } = require("./new-ab-tests");

const MARKETING_TEMPLATE_BOARD_IDS_DAYS_TO_EXPIRE = 30;
const STORIES_TEMPLATE_BOARD_IDS_DAYS_TO_EXPIRE = 7;

const TOP_BANNER_COOKIE = "top_banner_cookie";
const BIG_BRAIN_VISITOR_ID = "bb_visitor_id";
const EXPERIMENT_VISITOR_ID = "experiment_visitor_id";
const MONDAY_PLATFORM_USER_ID = "platform_user_id";
const MONDAY_PLATFORM_ACCOUNT_ID = "platform_account_id";
const USER_SELECTED_CLUSTER_COOKIE = "user_selected_cluster";
const USER_SELECTED_INDUSTRY_COOKIE = "user_selected_industry";
const ALWAYS_FS = "always_fs"; // always recording fullstory (where possible)
const HIDE_BANNER_WITH_BACKGROUND_COOKIE_NAME = "hide_banner_with_background";
const WEBSITE_PRODUCT_SIGNUP_COOKIE_NAME = "website_product_signup";

const M_CAMPAIGN = "m_campaign";
const M_CAMPAIGN_ID = "m_campaign_id";
const M_SOURCE = "m_source";
const M_BANNER = "m_banner";
const M_MEDIUM = "m_medium";
const M_CONTENT = "m_content";
const M_RETARGETING = "m_retargeting";
const M_REFERRER = "m_referrer";
const M_VERTICAL = "m_vertical";
const M_LANDING_PAGE = "m_landing_page";
const M_GIFT_SEND = "m_gift_send";
const M_AW_GRP = "m_aw_grp";
const M_AW_KW = "m_aw_kw";
const M_AW_AD = "m_aw_ad";
const HAS_FREE_TIER_INDICATION_COOKIE_NAME = "monday_has_free_tier"; // To sync platform to homepage
const HAS_STUDENT_PLAN_INDICATION_COOKIE_NAME = "monday_has_student_plan"; // To sync platform to homepage
const LAST_ACCOUNT_SLUG = "dapulseLastLoginAccount";
const DEFAULT_USERS_OPTION = "users_option"; //Sync platform to homepage
const MONDAY_FREE_TIER_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME =
  "monday_free_tier_account_creation_item_resource_credit";
const HOMEPAGE_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME = "homepage_account_creation_item_resource_credit";
const USE_OLD_STORAGE_SETTINGS = "use_old_storage_settings";
const IS_IN_KEEP_REGION_TEST = "is_in_keep_region_test"; // same name as in platform (defined in registration_service.rb)

const CLUSTER_COOKIE = "utm_cluster_id";
const SUB_CLUSTER_COOKIE = "utm_sub_cluster_id";
const PLATFORM_ACCOUNT_CLUSTER_COOKIE = "platform_account_cluster";
const PLATFORM_ACCOUNT_SUB_CLUSTER_COOKIE = "platform_account_sub_cluster";

const PLATFORM_WEBSITE_EXISTING_ACCOUNT_HEADER_TEST_COOKIE = "platform_test_website_existing_account_header_ab_test";

const WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME = "welcome_back_update_cta";
const WEBSITE_AB_TEST_DEBUG_COOKIE_NAME = "website_ab_test_debug";

const COUNTRY_COOKIE_NAME = "xi_country";
const CLOUDFRONT_VIEWER_COUNTRY_COOKIE_NAME = "cloudfront_viewer_country";
const CURRENCY_COOKIE_NAME = "force_currency_homepage";
const ACCOUNT_REFERRAL_COOKIE = "referral_token";
const HOMEPAGE_FORCE_200_ITEM_LIMITATION = "homepage_force_200_item_limitation";
const PLATFORM_PRICING_VERSION_COOKIE = "monday_pricing_version";
const PLATFORM_FREE_TIER_NAME_FREE_COOKIE = "platform_free_tier_name_free";
const PLATFORM_FREE_TIER_VERSION_COOKIE = "platform_free_tier_version";
const PLATFORM_HIDE_BASIC_COOKIE = "platform_hide_basic";
const PLATFORM_SHOW_SOLUTIONS_PRICING = "platform_show_solutions_pricing";

const LOCALE_COOKIE = "utm_locale_id";
const COOKIE_EXPIRY_DAYS_FOR_LOCALIZATION = 7;
const SKIP_CLUSTER_QUESTION = "skip_clusters_question";
const DAYS_TO_EXPIRE = 365;
const EXTEND_TRIAL_COOKIE_DAYS_EXPIRE_DEFAULT = 1;
const COUPON_DAYS_EXPIRE_DEFAULT = 1;

const MARKETING_TEMPLATE_BOARD_IDS = "marketing_template_board_ids";
const STORIES_TEMPLATE_BOARD_ID_SIGN_UP = "stories_template_board_id_sign_up";
const COUPON_CODE = "coupon_code";
const STORIES_TEMPLATE_BOARD_ID = "stories_template_board_id";
const EXTEND_TRIAL_COOKIE_KEY = "jwt_et";
const VIEWED_OUI_BOUNCE_MODAL_COOKIE = "viewedOuibounceModal";
const GRANTED_FEATURE_COOKIE = "granted_feature_cookie";
const COOKIES_DOMAIN = process.env.COOKIE_DOMAIN;
const IS_SECURE_COOKIE = process.env.IS_SECURE_COOKIE === "true";
const DEFAULT_COOKIES_SETTINGS = { path: "/", expires: DAYS_TO_EXPIRE, domain: COOKIES_DOMAIN, secure: true };

const COOKIES_CONFIG = {
  [TOP_BANNER_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [HIDE_BANNER_WITH_BACKGROUND_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [BIG_BRAIN_VISITOR_ID]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [EXPERIMENT_VISITOR_ID]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [MONDAY_PLATFORM_USER_ID]: { isMandatory: true, category: COOKIE_CATEGORIES.FUNCTIONAL },
  [MONDAY_PLATFORM_ACCOUNT_ID]: { isMandatory: true, category: COOKIE_CATEGORIES.FUNCTIONAL },
  [USER_SELECTED_CLUSTER_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [USER_SELECTED_INDUSTRY_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [WEBSITE_PRODUCT_SIGNUP_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [M_CAMPAIGN]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_CAMPAIGN_ID]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_SOURCE]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_BANNER]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_MEDIUM]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_CONTENT]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_RETARGETING]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_REFERRER]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_VERTICAL]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_LANDING_PAGE]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_GIFT_SEND]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_AW_GRP]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_AW_KW]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [M_AW_AD]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [MARKETING_TEMPLATE_BOARD_IDS]: {
    isMandatory: true,
    options: { expires: MARKETING_TEMPLATE_BOARD_IDS_DAYS_TO_EXPIRE, domain: COOKIES_DOMAIN },
    category: COOKIE_CATEGORIES.FUNCTIONAL
  },
  [STORIES_TEMPLATE_BOARD_ID_SIGN_UP]: {
    isMandatory: true,
    options: { expires: STORIES_TEMPLATE_BOARD_IDS_DAYS_TO_EXPIRE, domain: COOKIES_DOMAIN },
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [STORIES_TEMPLATE_BOARD_ID]: {
    isMandatory: true,
    options: { expires: STORIES_TEMPLATE_BOARD_IDS_DAYS_TO_EXPIRE, domain: COOKIES_DOMAIN },
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [COUPON_CODE]: {
    isMandatory: true,
    options: {
      expires: COUPON_DAYS_EXPIRE_DEFAULT
    },
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [EXTEND_TRIAL_COOKIE_KEY]: {
    isMandatory: true,
    options: {
      expires: EXTEND_TRIAL_COOKIE_DAYS_EXPIRE_DEFAULT
    },
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [COUNTRY_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [CLOUDFRONT_VIEWER_COUNTRY_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [ACCOUNT_REFERRAL_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [HOMEPAGE_FORCE_200_ITEM_LIMITATION]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [UserCampaignType.STORIES_CAMPAIGN]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [UserCampaignType.STORIES_SOURCE]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [UserCampaignType.STORIES_MEDIUM]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [UserCampaignType.STORIES_CONTENT]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [UserCampaignType.STORIES_CAMPAIGN_DATE]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [UserCampaignType.STORIES_SLUG]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [UserCampaignType.STORIES_TEMPLATE_TYPE]: { isMandatory: true, category: COOKIE_CATEGORIES.ANALYTICS },
  [StoriesCookies.MONDAY_USER_ID]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [StoriesCookies.MONDAY_ACCOUNT_SLUGS]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [HAS_FREE_TIER_INDICATION_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [MONDAY_FREE_TIER_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME]: {
    isMandatory: true,
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [USE_OLD_STORAGE_SETTINGS]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [IS_IN_KEEP_REGION_TEST]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [LOCALE_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.FUNCTIONAL },
  [SKIP_CLUSTER_QUESTION]: { isMandatory: true, category: COOKIE_CATEGORIES.FUNCTIONAL },
  [USER_SELECTED_LOCALE_ID_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.FUNCTIONAL },
  [CLUSTER_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [WEBSITE_AB_TEST_DEBUG_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY }, // not relevant to actual monday visitors
  [SUB_CLUSTER_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [PLATFORM_ACCOUNT_CLUSTER_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [PLATFORM_ACCOUNT_SUB_CLUSTER_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [PLATFORM_WEBSITE_EXISTING_ACCOUNT_HEADER_TEST_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [PRICE_PER_USER_AB_TEST]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [VIEWED_OUI_BOUNCE_MODAL_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.FUNCTIONAL },
  [CURRENCY_COOKIE_NAME]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [GRANTED_FEATURE_COOKIE]: { isMandatory: true, category: COOKIE_CATEGORIES.NECESSARY },
  [HOMEPAGE_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME]: {
    isMandatory: true,
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [PLATFORM_PRICING_VERSION_COOKIE]: {
    isMandatory: true,
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [PLATFORM_FREE_TIER_NAME_FREE_COOKIE]: {
    isMandatory: true,
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [PLATFORM_FREE_TIER_VERSION_COOKIE]: {
    isMandatory: true,
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [PLATFORM_HIDE_BASIC_COOKIE]: {
    isMandatory: true,
    category: COOKIE_CATEGORIES.NECESSARY
  },
  [PLATFORM_SHOW_SOLUTIONS_PRICING]: {
    isMandatory: true,
    category: COOKIE_CATEGORIES.NECESSARY
  }
};

module.exports = {
  TOP_BANNER_COOKIE,
  BIG_BRAIN_VISITOR_ID,
  EXPERIMENT_VISITOR_ID,
  MONDAY_PLATFORM_USER_ID,
  MONDAY_PLATFORM_ACCOUNT_ID,
  USER_SELECTED_CLUSTER_COOKIE,
  USER_SELECTED_INDUSTRY_COOKIE,
  WEBSITE_PRODUCT_SIGNUP_COOKIE_NAME,
  ALWAYS_FS,
  HIDE_BANNER_WITH_BACKGROUND_COOKIE_NAME,
  M_CAMPAIGN,
  M_CAMPAIGN_ID,
  M_SOURCE,
  M_BANNER,
  M_MEDIUM,
  M_CONTENT,
  M_RETARGETING,
  M_REFERRER,
  M_VERTICAL,
  M_LANDING_PAGE,
  M_GIFT_SEND,
  M_AW_GRP,
  M_AW_KW,
  M_AW_AD,
  HAS_FREE_TIER_INDICATION_COOKIE_NAME,
  HAS_STUDENT_PLAN_INDICATION_COOKIE_NAME,
  WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME,
  WEBSITE_AB_TEST_DEBUG_COOKIE_NAME,
  LAST_ACCOUNT_SLUG,
  DEFAULT_USERS_OPTION,
  MONDAY_FREE_TIER_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME,
  HOMEPAGE_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME,
  CLUSTER_COOKIE,
  SUB_CLUSTER_COOKIE,
  PLATFORM_ACCOUNT_CLUSTER_COOKIE,
  PLATFORM_ACCOUNT_SUB_CLUSTER_COOKIE,
  COUNTRY_COOKIE_NAME,
  CLOUDFRONT_VIEWER_COUNTRY_COOKIE_NAME,
  CURRENCY_COOKIE_NAME,
  ACCOUNT_REFERRAL_COOKIE,
  HOMEPAGE_FORCE_200_ITEM_LIMITATION,
  LOCALE_COOKIE,
  COOKIE_EXPIRY_DAYS_FOR_LOCALIZATION,
  SKIP_CLUSTER_QUESTION,
  DAYS_TO_EXPIRE,
  EXTEND_TRIAL_COOKIE_DAYS_EXPIRE_DEFAULT,
  COUPON_DAYS_EXPIRE_DEFAULT,
  MARKETING_TEMPLATE_BOARD_IDS,
  STORIES_TEMPLATE_BOARD_ID_SIGN_UP,
  COUPON_CODE,
  STORIES_TEMPLATE_BOARD_ID,
  EXTEND_TRIAL_COOKIE_KEY,
  VIEWED_OUI_BOUNCE_MODAL_COOKIE,
  GRANTED_FEATURE_COOKIE,
  COOKIES_DOMAIN,
  IS_SECURE_COOKIE,
  DEFAULT_COOKIES_SETTINGS,
  COOKIES_CONFIG,
  USE_OLD_STORAGE_SETTINGS,
  IS_IN_KEEP_REGION_TEST,
  PLATFORM_PRICING_VERSION_COOKIE,
  PLATFORM_FREE_TIER_NAME_FREE_COOKIE,
  PLATFORM_FREE_TIER_VERSION_COOKIE,
  PLATFORM_HIDE_BASIC_COOKIE,
  PLATFORM_SHOW_SOLUTIONS_PRICING,
  PLATFORM_WEBSITE_EXISTING_ACCOUNT_HEADER_TEST_COOKIE
};
