const parser = require("ua-parser-js");

const DEVICE_MOBILE_TYPE = "mobile";
const DEVICE_TABLET_TYPE = "tablet";

const OS_NAME_IOS = "iOS";
const OS_NAME_ANDROID = "Android";

const FACEBOOK_NAME = "Facebook";
const INSTAGRAM_NAME = "Instagram";

const SAFARI_NAME = "Safari";

const fetchUserAgent = () => {
  return typeof document !== "undefined" && typeof navigator !== "undefined"
    ? navigator.userAgent || navigator.vendor || window.opera
    : "";
};

const getViewerDeviceType = (user_agent) => {
  if (!user_agent) return null;

  const ua = parser(user_agent);
  return ua.device && ua.device.type;
};

const getViewerOsName = (user_agent) => {
  if (!user_agent) return null;

  const ua = parser(user_agent);
  return ua.os && ua.os.name;
};

const getViewerBrowser = (user_agent) => {
  if (!user_agent) return null;

  const ua = parser(user_agent);
  return ua.browser && ua.browser.name;
};

const isDesktopViewer = (user_agent) => {
  return getViewerDeviceType(user_agent) === undefined;
};

const isMobileViewer = (user_agent) => {
  return getViewerDeviceType(user_agent) === DEVICE_MOBILE_TYPE;
};

const isTabletViewer = (user_agent) => {
  return getViewerDeviceType(user_agent) === DEVICE_TABLET_TYPE;
};

const isMobileOrTabletViewer = (user_agent) => {
  return isMobileViewer(user_agent) || isTabletViewer(user_agent);
};

const isAndroidViewer = (user_agent) => {
  return getViewerOsName(user_agent) === OS_NAME_ANDROID;
};

const isFacebookViewer = (user_agent) => {
  return getViewerBrowser(user_agent) === FACEBOOK_NAME;
};

const isInstagramViewer = (user_agent) => {
  return getViewerBrowser(user_agent) === INSTAGRAM_NAME;
};

const isSafariViewer = (user_agent) => {
  return getViewerBrowser(user_agent) === SAFARI_NAME;
};

const isSafari = (userAgentParam) => {
  const userAgent = userAgentParam || fetchUserAgent();
  return isSafariViewer(userAgent);
};

const getUserAgentAnalyzedData = (user_agent) => {
  if (!user_agent) return {};
  const ua = parser(user_agent);

  const deviceType = ua.device && ua.device.type;
  const viewerOsName = ua.os && ua.os.name;

  return {
    isMobile: deviceType === DEVICE_MOBILE_TYPE,
    isTablet: deviceType === DEVICE_TABLET_TYPE,
    isDesktop: deviceType === undefined,
    isIos: viewerOsName === OS_NAME_IOS,
    isAndroid: viewerOsName === OS_NAME_ANDROID
  };
};

module.exports = {
  isDesktopViewer,
  isMobileViewer,
  isTabletViewer,
  isMobileOrTabletViewer,
  isAndroidViewer,
  isFacebookViewer,
  isInstagramViewer,
  getUserAgentAnalyzedData,
  isSafari,
  fetchUserAgent
};
