export const TRACK_EVENT = "track";

export const HP_SOURCE = "monday_homepage";

export const PAGE_VIEW = "page_view";
export const PREFETCH_ASSET_COMPLETED = "prefetched_asset";
export const PREFETCH_ALL_ASSETS_COMPLETED = "prefetch_all_assets_completed";

export const MOBILE_OR_TABLE_VIEW = "mobile_or_tablet_view";
export const SOCIAL_SHARE = "hp_social_share";
export const SIGNUP_FORM_GET_STARTED_CLICKED = "signup_form_get_started_clicked";
export const SIGNUP_FORM_SUBMIT = "signup_form_soft_signup";
export const SIGNUP_SECONDARY_BUTTON_CLICKED = "signup_secondary_button_clicked";

export const SIGNUP_MAIN_CTA_CLICKED = "signup_main_cta_clicked";
export const SIGNUP_WITH_GOOGLE_CLICKED = "signup_with_google_clicked";
export const SIGNUP_START_FILL_EMAIL = "signup_user_email_input_start_fill";
export const SIGNUP_SUBMIT_CLICK_WITH_EMAIL_ERROR = "signup_submit_click_with_email_error";
export const EXISTING_ACCOUNT_MAIN_CTA_CLICKED = "existing_account_main_cta_clicked";

export const BUTTON_LINK_CLICKED = "button_link_clicked";
export const SET_CLUSTER_ID_EVENT_NAME = "set_cluster_id";
export const SET_CLUSTER_EVENT = "set_cluster";
export const SET_SUB_CLUSTER_ID_EVENT_NAME = "set_sub_cluster_id";
export const SET_LOCALE_ID_EVENT_NAME = "set_locale_id";
export const CLICKED_SIGNUP_WITHOUT_INSERTING_EMAIL = "clicked_signup_without_inserting_email";

export const PRICING_PLAN_SELECTED = "hp_opened_pricing_plan_popup";
export const PRICING_INFO_ICON_HOVER = "pricing_info_icon_hover";

export const YOUTUBE_MODAL_OPEN = "youtube_modal_video_opened";
export const YOUTUBE_MODAL_CLOSE = "youtube_modal_video_closed";
export const YOUTUBE_MODAL_OPEN_SECONDARY = "youtube_modal_video_opened_secondary";
export const YOUTUBE_MODAL_CLOSE_SECONDARY = "youtube_modal_video_closed_secondary";
export const YOUTUBE_VIDEO_STARTED = "youtube_video_started";
export const YOUTUBE_VIDEO_ENDED = "youtube_video_ended";

export const EXIT_POPUP_OPEN = "exit_popup_open";
export const EXIT_POPUP_CLOSE = "exit_popup_close";
export const EXIT_POP_UP_REASON = "exit_popup_reason";

export const PRICING_USERS_SELECTED = "pricing_plan_selected";
export const PRICING_BILLING_PERIOD_SELECTED = "pricing_billing_period_selected";

export const PRICING_PAGE_FAQ_QUESTION_CLICK = "pricing_page_faq_question_click";
export const PRICING_PAGE_FAQ_SECTION_VISIBLE = "pricing_page_faq_section_visible";
export const PRICING_PAGE_FAQ_QUESTIONS_BUTTON_CLICK = "pricing_page_faq_questions_button_click";
export const PRICING_PAGE_FAQ_QUESTION_LINK_CLICK = "pricing_page_faq_question_link_click";

export const PRICING_PAGE_FEATURE_INFO_ICON_HOVER = "pricing_page_feature_info_icon_hover";
export const PRICING_PAGE_FEATURE_INFO_ICON_EXIT = "pricing_page_feature_info_icon_exit";

export const PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED = "pricing_page_features"; // more info is delivered in the 'kind' parameter.
export const PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED = "pricing_page_missing_cluster_info_encountered";

export const PRICING_PAGE_PURCHASE_NOW_CLICKED = "pricing_page_purchase_now_clicked";
export const PRICING_PAGE_PURCHASE_NOW_REDIRECT = "pricing_page_purchase_now_redirect";
export const PRICING_PAGE_PURCHASE_NOW_REDIRECT_FAILURE = "pricing_page_purchase_now_redirect_failure";

export const FOOTER_LINK_CLICK = "footer_link_click";
export const SIDE_MENU_LINK_CLICK = "side_menu_link_click";

export const LANGUAGE_PICKER_LANG_CHANGED = "language_picker_language_changed";
export const LANGUAGE_PICKER_OPENED = "language_picker_opened";

export const MOBILE_DOWNLOAD_LINK_CLICK = "mobile_download_link_click";

export const CAROUSEL_SWITCH_BOARD_CLICKED = "carousel_click_switch_board";
export const CAROUSEL_BOARD_SWITCHED = "carousel_board_switched";
export const CAROUSEL_PAGE_CLICKED = "carousel_click_page";
export const CAROUSEL_ARROW_CLICKED = "carousel_click_arrow";

export const PAGE_SCROLL_BY_PERCENTAGE = "page_scroll_by_percentage";
export const SCROLL_INDICATION_CLICKED = "scroll_indication_clicked";

export const CLUSTER_CLICKED = "value_before_signup_cluster_clicked";
export const CLUSTER_SELECTED = "value_before_signup_cluster_selected";

export const PRODUCT_RECOMMENDED_BY_TAGS_SELECTION = "product_recommended_by_tags_selection";

export const MOBILE_CLUSTER_CLICKED = "value_before_signup_mobile_cluster_clicked";
export const MOBILE_CLUSTER_SELECTED = "value_before_signup_mobile_cluster_selected";

export const LAYOUT_CLICKED = "value_before_signup_layout_clicked";
export const LAYOUT_SELECTED = "value_before_signup_layout_selected";

export const INDUSTRY_SELECTED = "value_before_signup_industry_selected";
export const INDUSTRY_MANAGE_OPTION_CLICKED = "value_before_signup_industry_manage_option_clicked";
export const INDUSTRY_NEXT_CLICKED = "value_before_signup_industry_next_clicked";

export const OPEN_HELP_AVATAR = "open_help_avatar";
export const HELP_AVATAR_LINK_CLICKED = "help_avatar_link_clicked";
export const CLOSE_HELP_AVATAR = "close_help_avatar";

export const HUBSPOT_FORM_SUBMIT_CLICKED = "hubspot_form_submit_clicked";
export const HUBSPOT_FORM_SUBMIT_FAILED = "hubspot_form_submit_failed";
export const HUBSPOT_FORM_READY = "hubspot_form_ready";
export const HUBSPOT_FORM_INPUT_BLUR = "hubspot_form_input_blur";

// Page performance tracking events
export const PAGE_PERFORMANCE_TRACKING_GENERIC_ERROR = "page_performance_tracking_generic_error";
export const PAGE_PERFORMANCE_TRACKING_NOT_SUPPORTED = "page_performance_tracking_not_supported";
export const PAGE_PERFORMANCE_TRACKING_DATA = "page_performance_tracking_data";
export const PAGE_PERFORMANCE_TRACKING_ERROR = "page_performance_tracking_error";
export const PAGE_PERFORMANCE_TRACKING_RESOURCES = "page_performance_tracking_resources";
export const PAGE_PERFORMANCE_TRACKING_RESOURCES_ERROR = "page_performance_tracking_resources_error";

// Community
export const STORIES_SOURCE = "monday_stories";
export const SHARE_NEW_TEMPLATE_CLICK = "share_new_community_template_click";
export const SHOW_TEMPLATES = "show_community_templates";
export const COMMUNITY_GENERATOR_TEMPLATE_BUTTON = "community_generator_button"; // save / publish / cancel
export const UPDATE_COMMUNITY_TEMPLATE_STATUS = "community_template_status_update";
export const LINK_BOARD_TO_MONDAY_STORIES = "monday_stories_link_board";
export const UNLINK_BOARD_TO_MONDAY_STORIES = "monday_stories_unlink_board";
export const USE_BOARD_FROM_MONDAY_STORIES = "monday_stories_use_board";
export const SIGN_UP_FROM_TEMPLATE = "monday_stories_sign_up_from_board";
export const EMPTY_TEMPLATE_CLICK = "monday_stories_empty_template_click";
export const USER_TEMPLATE_APPROVAL = "monday_stories_user_approval";
export const USER_TERMS_APPROVAL = "monday_stories_user_terms_of_use_approval";
export const CATEGORY_CLICK = "monday_stories_category_click";
export const CATEGORY_SHOW_ALL_CLICK = "monday_stories_category_show_all_click";
export const SHOW_MORE_CLICK = "monday_stories_show_more_click";
export const SHOW_TOP_5_STORIES = "monday_stories_show_top_5";
export const TOPBAR_SIGNUP_LINK_CLICK = "monday_stories_top_bar_sign_up";

//GENERATOR
export const GENERATOR_PAGE_ARCHIVE_CLICK = "generator_page_archive_click";

//TOP BAR
export const TOP_BANNER_CLOSED_CLICK = "top_banner_close_clicked";

//IFRAME
export const IFRAME_COMPONENT_RENDERED = "iframe_component_rendered";
export const IFRAME_LOADED = "iframe_loaded";
export const IFRAME_LOAD_ERROR = "iframe_load_error";

export const EMBEDDED_BOARD_INITIAL_INTERACTION = "embedded_board_initial_interaction";

//SUBSCRIBE BUTTON
export const SUBSCRIBE_BUTTON_BIGBRAIN_EVENT_NAME = "homepage_subscribe_button_tracking";
export const SUBSCRIBE_BUTTON_RENDERED = "subscribe_button_rendered";
export const CLICKED_SUBSCRIBE_BUTTON = "clicked_subscribe_button";
export const SUBSCRIPTION_REQUESTED = "subscription_requested";
export const SUBSCRIPTION_SUCCESS = "subscription_success";

export const CAREER_PAGE_CLICK = "career_page_click";
export const CAREER_KIND_SEARCH_FIELD_START_TYPING = "search_field_start_typing";
export const CAREER_KIND_SEARCH_FIELD_SUBMITTED = "search_field_submitted";
export const CAREER_KIND_FILTER_BY_LOCATION = "filter_by_location";
export const CAREER_KIND_FILTER_BY_TEAM = "filter_by_team";
export const CAREER_KIND_LOCATION_FILTER_CHOSEN = "location_filter_chosen";
export const CAREER_KIND_TEAM_FILTER_CHOSEN = "team_filter_chosen";
export const CAREER_KIND_IMAGES_HOVERED = "images_hovered";
export const CAREER_TEAM_FILTER_CHOSEN = "team_filter_chosen";
export const CAREER_KIND_SHOW_MORE_OPTIONS = "show_more_options";
export const CAREER_KIND_DIDNT_FIND_POSITION = "didnt_find_position";
export const CAREER_KIND_SPECIFIC_POSITION_CLICKED = "specific_position_clicked";
export const CAREER_KIND_NEWS_ITEM_CLICKED = "news_item_clicked";
export const CAREER_KIND_BLOG_ITEM_CLICKED = "blog_item_clicked";
export const CAREER_KIND_PODCAST_ITEM_CLICKED = "podcast_item_clicked";
export const CAREER_KIND_SEARCH_OPEN_POSITION_BOTTOM_CTA = "search_open_position_bottom_cta";
export const CAREER_KIND_BACK_TO_CARRER_PAGE_CTA = "back_to_careers_page_cta";

export const CAREER_POSITIONS_RETRIEVE_FROM_COMEET = "career_positions_retrieve_from_comeet";
export const CAREER_POSITIONS_RETRIEVE_FROM_COMEET_ERROR = "career_positions_retrieve_from_comeet_error";
export const CAREER_COMEET_RETRIEVE_POSITIONS_TIMEOUT = "career_comeet_retrieve_positions_timeout";

export const MOUSE_ENTER_IMAGE_ELEMENT = "mouse_enter_image_element";
export const CLICK_IMAGE_ELEMENT = "click_image_element";

export const SET_SKIP_QUESTION_COOKIE = "set_skip_question_cookie";

// TIERS WITH VALUE PRICING PAGE
export const CONTACT_US_BUTTON_CLICKED = "contact_us_button_clicked";
export const SEE_COMPLETE_FEATURES_LIST_CLICKED = "see_complete_features_list_clicked";
export const PRICING_PAGE_FEATURE_LINK_CLICKED = "pricing_page_feature_link_clicked";
export const CONTACT_SALES_FORM_SUBMISSION_FAILED = "contact_sales_form_submission_failed";

//  TESTS
export const DOM_CHANGED = "dom_changed_v3";
export const DOM_CHANGED_UNAVAILABLE = "dom_changed_unavailable";
export const DOM_CHANGE_EVENT_FAILURE = "dom_change_event_failure";

//  ERRORS
export const SERVER_EXCEPTION = "server_exception";
export const CAREERS_ERROR_RENDERED = "careers_error_rendered";

// SELECTED TAG COMPONENT
export const SELECTED_TAG_EVENT = "selected_tag_event";
export const CAROUSEL_SWIPED = "carousel_swiped";
export const CAROUSEL_SLIDE_CHANGED = "carousel_slide_changed";

// TAG COMPONENT
export const GRID_TAG_CLICKED = "grid_tag_clicked";
export const GRID_TAG_HOVERED = "grid_tag_hovered";

// MINI SITE
export const MINI_SITE_CLUSTER_NOT_VALID = "mini_site_cluster_not_valid";

//ZENDESK

export const ZENDESK_FORM_SUBMIT_CLICKED = "zendesk_form_submit_clicked";
export const ZENDESK_FORM_SUBMIT_SUCCESS = "zendesk_form_submit_success";
export const ZENDESK_FORM_SUBMIT_FAILED = "zendesk_form_submit_failed";

//TRACKING
export const PAGE_USAGE_DURATION = "page_usage_duration";
export const BIGBRAIN_IFRAME_LOCALSTORAGE_EVENTS = "bigbrain_iframe_localstorage_events";
export const WEBSITE_SYNC_VISITOR_AND_AB_TEST_EXPERIMENT_ID = "website_sync_visitor_and_ab_test_experiment_id";
export const BIGBRAIN_IFRAME_COOKIE_SYNC_ERROR = "bigbrain_iframe_cookie_sync_error";

//HEADER
export const HEADER_LINK_CLICKED = "header_link_clicked";
export const HEADER_SUBMENU_LINK_CLICKED = "header_submenu_link_clicked";
export const HEADER_SUBMENU_SEE_MORE_CLICKED = "header_submenu_see_more_clicked";
export const SECONDARY_HEADER_LINK_CLICKED = "secondary_header_link_clicked";
export const HEADER_SUBMENU_OPENED = "header_submenu_opened";
export const HEADER_SUBMENU_CLOSED = "header_submenu_closed";
export const HEADER_PRODUCT_TAG_HOVERED = "header_product_tag_hovered";
export const HEADER_LEFT_PANEL_ITEM_CLICKED = "header_left_panel_item_clicked";
export const HEADER_LEFT_PANEL_SUBITEM_CLICKED = "header_left_panel_subitem_clicked";
export const HEADER_LEFT_PANEL_SUBITEM_CONTENT_VIEWED = "header_left_panel_subitem_content_viewed";

//LINKS
export const PICTURE_WITH_LINK_CLICKED = "picture_with_link_clicked";
export const REGULAR_BUTTON_CLICKED = "regular_button_clicked";
export const SECONDARY_BUTTON_CLICKED = "secondary_button_clicked";
export const TAB_CLICKED = "tab_clicked";
export const POPUP_LINK_CLICKED = "popup_link_clicked";
export const BANNER_LINK_CLICKED = "banner_link_clicked";
export const BOARD_TAB_CLICKED = "board_tab_clicked";
export const BOARD_TAB_HOVERED = "board_tab_hovered";

// GRID IMAGES
export const GRID_CARD_CLICKED = "grid_card_clicked";
export const GRID_CARD_HOVERED = "grid_card_hovered";
export const FLEXIBLE_GRID_CARD_CLICKED = "flexible_grid_card_clicked";
export const FLEXIBLE_GRID_CARD_HOVERED = "flexible_grid_card_hovered";

//COOKIES GDPR
export const COOKIE_UNCATEGORIZED = "website_uncategorized_cookie";
export const COOKIE_CATEGORY_GTM_DATALAYER_NOT_SET = "cookie_category_gtm_datalayer_not_set";
export const COOKIE_CATEGORY_NOT_APPROVED = "cookie_category_not_approved";

//FAQ
export const FREQUENTLY_ASKED_QUESTION_CLICKED = "frequently_asked_question_clicked";

//Thumbnail list
export const THUMBNAIL_LIST_LINK_CLICKED = "thumbnail_list_link_clicked";
export const THUMBNAIL_LIST_BOTTOM_LINK_CLICKED = "thumbnail_list_bottom_link_clicked";

//Customer testimonials
export const CUSTOMER_TESTIMONIAL_CHANGED_MANUALLY = "customer_testimonial_changed_manually";

//Solutions
export const SOLUTION_PAGE_WITH_INVALID_SOLUTION_ERROR = "solution_page_with_invalid_solution_error";
export const SOLUTION_CATEGORY_PAGE_WITH_INVALID_CATEGORY_ERROR = "solution_page_with_invalid_category_error";
export const SOLUTION_PAGE_ERROR = "solution_page_error";
export const SOLUTION_CATEGORY_PAGE_ERROR = "solution_category_page_error";
export const SOLUTION_CARD_CLICKED = "solution_card_clicked";
export const FEATURED_CATEGORY_CLICKED = "featured_category_clicked";
export const MENU_CATEGORY_CLICKED = "menu_category_clicked";
export const SOLUTION_GALERY_ITEM_CLICKED = "solution_galery_item_clicked";
export const SOLUTION_GALERY_VIDEO_INTERACTION = "solution_galery_video_interaction";
export const SOLUTION_STORE_SEARCH = "solution_store_search";
export const SOLUTION_STORE_SEARCH_COMPLETED = "solution_store_search_completed";

export const SIGN_UP_WITH_TEMPLATE_CLICKED = "sign_up_with_template_clicked";

// Client performance metrics
export const CLIENT_PERFORMANCE_MEASUREMENT = "client_performance_measurement";

// HEALTH
export const SEGMENT_LAYOUT_NOT_FOUND = "segment_layout_not_found";
export const CLIENT_EXCEPTION = "client_exception";

// ERROR
export const PLATFORM_MANIFEST_FETCH_ERROR = "platform_manifest_fetch_error";
export const CLUSTER_INTERNAL_FETCH_ERROR = "cluster_internal_fetch_error";
export const CLUSTER_INTERNAL_ERROR = "cluster_internal_error"; // should catch cache and network errors
export const TRANSLATIONS_INTERNAL_ERROR = "translations_internal_error"; // should catch cache and network errors
export const RETRIEVED_404_ERROR = "404_page_retrieved";

export const CLIENT_ERROR_STATE_CAUGHT = "website_client_error_caught";

// DRIFT

export const DRIFT_CHAT_LOADED = "drift_chat_loaded";
export const DRIFT_COVERSATION_STARTED = "drift_coversation_started";
export const DRIFT_MEETING_BOOKED = "drift_meeting_booked";
export const DRIFT_AGENT_REPLIED = "drift_agent_replied";
export const DRIFT_MESSAGE_RECEIVED = "drift_message_received";

// SIGNUP POPUP
export const SIGNUP_POPUP_OPENED = "signup_popup_opened";
export const SIGNUP_POPUP_CLOSED = "signup_popup_closed";

// PRODUCTS MOBILE COMPONENT
export const MOBILE_PRODUCT_COMPONENT_OPENED = "mobile_product_component_opened";
export const MOBILE_PRODUCT_COMPONENT_CLOSED = "mobile_product_component_closed";

// WELCOME BACK
export const WELCOME_PAGE_DIDNT_FIND_ACCOUNT_CLICKED = "welcome_page_didnt_find_account_clicked";
export const WELCOME_PAGE_GO_TO_ACCOUNT_CLICKED = "welcome_page_go_to_account_clicked";
export const WELCOME_PAGE_LOADED_WITH_NO_ACCOUNTS = "welcome_page_loaded_with_no_accounts";
export const WELCOME_PAGE_LOADED = "welcome_page_loaded";

// DEMO COMPONENT
export const DEMO_ASSET_CLICKED = "demo_asset_clicked";
export const DEMO_ASSET_LEFT_SIDE_CLICKED = "demo_asset_left_side_clicked";
export const DEMO_STEP_CLICKED = "demo_step_clicked";

// TYPEFORM
export const TYPEFORM_CONTACT_SALES_QUESTION_CHANGED = "typeform_contact_sales_question_changed";
export const TYPEFORM_CONTACT_SALES_FORM_LOADED = "typeform_contact_sales_form_loaded";
export const TYPEFORM_CONTACT_SALES_FORM_SUBMITTED = "typeform_contact_sales_form_submitted";
export const TYPEFORM_CONTACT_SALES_FORM_CLOSED = "typeform_contact_sales_form_closed";
export const TYPEFORM_UNHANDLED_EXCEPTION = "typeform_unhandled_exception";

//SIGN UP WITH PRODUCTS
export const SIGNUP_PRODUCT_TAG_HOVERED = "signup_product_tag_hovered";
export const SIGNUP_PRODUCT_CARD_CLICKED = "signup_product_card_clicked";
export const CONTINUE_BUTTON_CLICKED = "signup_products_continue_button_clicked";
export const SKIP_BUTTON_CLICKED = "signup_products_skip_button_clicked";
export const SIGN_UP_WITH_PRODUCTS_LOADED = "sign_up_with_products_loaded";

//LIVE BOARD
export const LIVE_BOARD_OPENED = "live_board_opened";
export const LIVE_BOARD_CLOSED = "live_board_closed";
export const LIVE_BOARD_OPEN_BUTTON_HOVERED = "live_board_open_button_hovered";
export const LIVE_BOARD_SCALED = "live_board_scaled";
export const LIVE_BOARD_OPEN_BUTTON_CLICKED = "live_board_open_button_clicked";

//Asset component
export const ASSET_COMPONENT_IS_FULLY_VISIBLE = "asset_component_is_fully_visible";
