import Cookies from "js-cookie";
import {
  COOKIE_UNCATEGORIZED,
  COOKIE_CATEGORY_GTM_DATALAYER_NOT_SET,
  COOKIE_CATEGORY_NOT_APPROVED
} from "constants/bigbrain-event-types";

import { COOKIES_CONFIG, DEFAULT_COOKIES_SETTINGS } from "constants/cookies";

import { getDataLayerVariable } from "client/services/gtm-service/gtm-service";
import { COOKIE_CATEGORIES_GTM_MAPPINGS } from "constants/cookies-categories";
import { isRunningInClient } from "services/dom-utils-service";
import { trackEvent } from "/services/bigbrain-service";

Cookies.defaults = DEFAULT_COOKIES_SETTINGS;

export const getAllCookies = () => {
  return Cookies.get();
};

export const getCookie = (cookieKey) => {
  return Cookies.get(cookieKey);
};

export const setCookie = (cookieKey, cookieValue, options = {}) => {
  const cookieSettings = COOKIES_CONFIG[cookieKey] || {};
  const runningInClient = isRunningInClient();

  if (!runningInClient) {
    console.log(`setCookie ${cookieKey} called on server side`);
  }

  if (Object.keys(cookieSettings).length === 0 || !cookieSettings?.category) {
    console.error(`Cookie ${cookieKey} is not categorized`);
    trackEvent(COOKIE_UNCATEGORIZED, { kind: cookieKey });
  }

  if (isInClientAndCookieCategoryNotApproved(cookieSettings.category)) {
    // console.log(`not writing cookie ${cookieKey}`);

    // if cookiehub isn't set  verify we are not set to false and uncomment the return
    trackEvent(COOKIE_CATEGORY_NOT_APPROVED, { kind: cookieSettings.category, info1: cookieKey, info2: cookieValue });
    // return;
  }
  return Cookies.set(cookieKey, cookieValue, { ...DEFAULT_COOKIES_SETTINGS, ...cookieSettings.options, ...options });
};

export const setCookieIfNeeded = (cookieKey, cookieValue, options = {}, bigBrainEventName) => {
  if (!getCookie(cookieKey) && cookieValue) {
    setCookie(cookieKey, cookieValue, options);
    if (bigBrainEventName) {
      trackEvent(bigBrainEventName);
    }
  }
};

export const removeCookie = (cookieKey) => {
  return Cookies.remove(cookieKey);
};

const isCookieCategoryApproved = (category) => {
  const gtmAllowedDataLayerVariable = COOKIE_CATEGORIES_GTM_MAPPINGS[category]?.gtmAllowedDataLayerVariable;
  const categoryApproved = getDataLayerVariable(gtmAllowedDataLayerVariable);

  if (typeof categoryApproved == "undefined" || categoryApproved === null) {
    //will uncomment BB event once fully released
    trackEvent(COOKIE_CATEGORY_GTM_DATALAYER_NOT_SET, { kind: category });
    console.log(`cookie category ${category} not set in GTM datalayer`);
  }

  return categoryApproved !== false;
};

export const isInClientAndCookieCategoryNotApproved = (category) => {
  if (!isRunningInClient()) return false; //Approval data resides in GTM data layer can't access it from server code

  return !isCookieCategoryApproved(category);
};
