import { GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER } from "/constants/marketing-keys";
import { isRunningInClient } from "services/dom-utils-service";
import { getUserAgentAnalyzedData } from "services/user-agent-service";

export const fireGtmEvent = (eventName, eventData = {}) => {
  if (!window.dataLayer) return;

  const data = { ...eventData, event: eventName };
  window.dataLayer.push(data);
};

export const updateDataLayerVariables = (variablesList) => {
  if (!window.dataLayer) return;

  window.dataLayer.push(variablesList);
};

//fetches the lates variable from dataLayer: https://www.simoahava.com/analytics/fetch-latest-value-data-layer-variable/
export const getDataLayerVariable = (varName) => {
  return (
    window?.google_tag_manager &&
    window.google_tag_manager[GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER]?.dataLayer?.get(varName)
  );
};

export const addGTMVariablesToWindow = ({ localeId, clusterId, hasFreeTier }) => {
  if (!isRunningInClient()) return;

  const userAgent = navigator.userAgent;
  const userAgentData = getUserAgentAnalyzedData(userAgent);

  window.gtmIsMobile = userAgentData.isMobile;
  window.gtmIsTablet = userAgentData.isTablet;
  window.gtmIsDesktop = userAgentData.isDesktop;
  window.gtmIsIos = userAgentData.isIos;
  window.gtmIsAndroid = userAgentData.isAndroid;
  window.gtmLocaleId = localeId;
  window.gtmClusterId = clusterId;
  window.gtmIsFreeTier = hasFreeTier;
};
