const DEBUG_AB_TEST_PARAM_NAME = "debug-ab-test-name";

//AB TESTS
const PRICE_PER_USER_AB_TEST = "homepage_price_per_user_ab_test";
const NEW_COOKIES_POLICY_AB_TEST = "new_cookies_policy_ab_test";
const SOFT_SIGNUP_TRIAL_CTA_HOMEPAGE_AB_TEST = "soft_signup_trial_cta_homepage_ab_test";
const SOFT_SIGNUP_TRIAL_CTA_LP_AB_TEST = "soft_signup_trial_cta_lp_ab_test";
const SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST = "skip_sign_up_step_in_pricing_page_ab_test";
const DRIFT_PRICING_CHATBOT_CONTACT_SALES_AB_TEST = "drift_pricing_chatbot_contact_sales_ab_test";
const HOMEPAGE_PRICE_PER_SEAT_NEW_AB_TEST = "homepage_price_per_seat_new_ab_test";
const EXIT_POP_UP_SURVEY_AB_TEST = "exit_pop_up_survey_ab_test";
const TRY_FOR_FREE_CTA_AB_TEST = "try_for_free_cta_ab_test";
const HOMEPAGE_PRICE_PER_SEAT_V3_AB_TEST = "homepage_price_per_seat_v3_ab_test";

const LEARN_MORE_AND_SIGNUP_BUTTON = {
  test_name: "learn_more_and_signup_button",
  new_variation_name: "new_with_learn_more_and_signup_button"
};

const LEARN_MORE_TO_PRODUCT_BUTTON = {
  test_name: "learn_more_to_product_button_new",
  new_variation_name: "new_with_learn_more_to_product_button"
};

const REVIVE_EXIT_POPUP_AB_TEST = {
  test_name: "revive_exit_popup_ab_test",
  new_variation_name: "new_with_exit_popup"
};

const PERFORMANCE_STATIC_PAGE_AB_TEST = {
  test_name: "performance_static_page_ab_test",
  new_variation_name: "new_static_page"
};

const BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES = {
  test_name: "homepage_bigger_storage_in_pricing_page_features",
  new_variation_name: "new_with_bigger_storage"
};

const TEMPLATES_IN_HEADER_AB_TEST = {
  test_name: "homepage_templates_in_header_ab_test",
  new_variation_name: "new_with_templates"
};

const HOMEPAGE_V10_PRICING_VERSION_AB_TEST = {
  test_name: "homepage_v10_pricing_version_ab_test",
  new_variation_name: "new_with_v10"
};

const HOMEPAGE_FREE_TIER_NAME_FREE_AB_TEST = {
  test_name: "homepage_free_tier_name_free_ab_test",
  new_variation_name: "new_with_name_free"
};

const HOMEPAGE_FREE_TIER_2022_ABC_TEST = {
  test_name: "homepage_free_tier_2022_ab_test",
  new_variation_name_b: "new_with_free_tier_2022_a",
  new_variation_name_c: "new_with_free_tier_2022_b"
};

const DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST = {
  test_name: "desktop_with_enterprise_in_submenu_ab_test",
  new_variation_name: "new_with_enterprise_in_submenu"
};

const HOMEPAGE_HIDE_BASIC_AB_TEST = {
  test_name: "homepage_hide_basic_ab_test",
  new_variation_name: "new_with_hide_basic"
};

const HOMEPAGE_PRODUCT_SOLUTIONS_AB_TEST = {
  test_name: "homepage_product_solutions_ab_test",
  new_variation_name: "new_with_product_solutions"
};

const PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST = {
  test_name: "pricing_enterprise_contact_sales_ab_test",
  new_variation_name: "new_enterprise_page"
};

const LEARN_MORE_BUTTONS_IN_HEADER_TEST = {
  test_name: "learn_more_in_header",
  new_variation_name: "new_learn_more_in_header"
};

const MOBILE_STICKY_SIGNUP_AB_TEST = {
  test_name: "mobile_sticky_signup_ab_test",
  new_variation_name: "new_with_sticky_signup"
};

const ENRICHMENT_HOMEPAGE_SALES_CTA_AB_TEST = {
  test_name: "enrichment_homepage_sales_cta_ab_test",
  new_variation_name: "new_with_sales_cta"
};

const ENRICHEMNT_DRIFT_CRM_LP_AB_TEST = {
  test_name: "enrichemnt_drift_open_crm_lp_ab_test_v2",
  new_variation_name: "new_with_drift"
};

const MAIN_SIGNUP_AB_TEST = {
  test_name: "main_signup_with_logos_image_fullscreen_ab_test",
  new_variation_name: "new_with_logos_fullscreen_signup"
};

const LP_SIGNUP_POPUP_WITHOUT_ASSET_AB_TEST = {
  test_name: "lp_signup_popup_without_asset_ab_test",
  new_variation_name: "new_with_popup"
};

const WELCOME_BACK_PAGE_TEST = {
  test_name: "welcome_back_account_homepage_return_to_account_cta_test_2", // test name must include prefix: "welcome_back_account_homepage", Bigbrain Sync issue: https://github.com/DaPulse/BigBrain/pull/8257/files
  new_variation_name: "new_with_welcome_back",
  launch_date: "2022-11-08"
};

// THIS TEST IS DEFINED AND RAFFELED IN THE PLATFORM
const PLATFORM_EXISTING_ACCOUNT_HEADER_TEST = {
  test_name: "website_existing_account_header_ab_test",
  new_variation_name: "new_existing_account_header"
};

const KEEP_REGION_TEST = {
  test_name: "keep_region_au_ab_test",
  new_variation_name: "new_with_keep_region"
};

const CONTACT_SALES_TYPEFORM_EMEA = {
  test_name: "contact_sales_typeform_emea",
  new_variation_name: "new_with_typeform"
};

const CONTACT_SALES_TYPEFORM_ANZ = {
  test_name: "contact_sales_typeform_anz",
  new_variation_name: "new_with_typeform"
};

const CONTACT_SALES_MOBILE_TYPEFORM_TEST = {
  test_name: "mobile_contact_sales_typeform_first_question_in_first_screen_test",
  new_variation_name: "new_with_mobile_typeform"
};

const SIGNUP_WITH_PRODUCT_PAGE_WITHOUT_PRODUCT_RECOMMENDATION_TEST = {
  test_name: "new_signup_with_product_page_without_product_recommendation_test",
  new_variation_name: "new_signup_with_product_page"
};

const SIGNUP_WITH_PRODUCT_PAGE_WITH_PRODUCT_RECOMMENDATION_TEST = {
  test_name: "new_signup_with_product_page_with_product_recommendation_test",
  new_variation_name: "new_signup_with_product_page"
};

const HEADER_NEW_PRODUCTS_OFFERING_TEST = {
  test_name: "header_new_products_offering_more_clickable_test",
  new_variation_name: "new_header_products_offering"
};

const CS_1_19_PHONE_SUPPORT_TEST = {
  test_name: "cs_1_19_phone_support_test",
  new_variation_name: "new_with_phone_support_group"
};

const LANGUAGE_PICKER_IN_HEADER_TEST = {
  test_name: "language_picker_in_header_test_v2",
  new_variation_name: "new_with_language_picker_in_header"
};

const UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST = {
  test_name: "unlimited_time_free_plan_cta_text_test",
  new_variation_name: "new_unlimited_time_cta"
};

const SHOW_SUCCESS_STORIES_IN_HEADER_TEST = {
  test_name: "show_customers_stories_in_header_test",
  new_variation_name: "new_with_success_stories_in_header"
};

module.exports = {
  DEBUG_AB_TEST_PARAM_NAME,
  PRICE_PER_USER_AB_TEST,
  NEW_COOKIES_POLICY_AB_TEST,
  SOFT_SIGNUP_TRIAL_CTA_HOMEPAGE_AB_TEST,
  SOFT_SIGNUP_TRIAL_CTA_LP_AB_TEST,
  SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST,
  DRIFT_PRICING_CHATBOT_CONTACT_SALES_AB_TEST,
  HOMEPAGE_PRICE_PER_SEAT_NEW_AB_TEST,
  EXIT_POP_UP_SURVEY_AB_TEST,
  LEARN_MORE_AND_SIGNUP_BUTTON,
  LEARN_MORE_TO_PRODUCT_BUTTON,
  HOMEPAGE_PRICE_PER_SEAT_V3_AB_TEST,
  TRY_FOR_FREE_CTA_AB_TEST,
  REVIVE_EXIT_POPUP_AB_TEST,
  PERFORMANCE_STATIC_PAGE_AB_TEST,
  BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES,
  TEMPLATES_IN_HEADER_AB_TEST,
  HOMEPAGE_V10_PRICING_VERSION_AB_TEST,
  HOMEPAGE_FREE_TIER_NAME_FREE_AB_TEST,
  HOMEPAGE_FREE_TIER_2022_ABC_TEST,
  DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST,
  HOMEPAGE_HIDE_BASIC_AB_TEST,
  HOMEPAGE_PRODUCT_SOLUTIONS_AB_TEST,
  PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST,
  MOBILE_STICKY_SIGNUP_AB_TEST,
  LEARN_MORE_BUTTONS_IN_HEADER_TEST,
  ENRICHMENT_HOMEPAGE_SALES_CTA_AB_TEST,
  ENRICHEMNT_DRIFT_CRM_LP_AB_TEST,
  MAIN_SIGNUP_AB_TEST,
  LP_SIGNUP_POPUP_WITHOUT_ASSET_AB_TEST,
  WELCOME_BACK_PAGE_TEST,
  PLATFORM_EXISTING_ACCOUNT_HEADER_TEST,
  KEEP_REGION_TEST,
  CONTACT_SALES_TYPEFORM_EMEA,
  CONTACT_SALES_TYPEFORM_ANZ,
  CONTACT_SALES_MOBILE_TYPEFORM_TEST,
  SIGNUP_WITH_PRODUCT_PAGE_WITHOUT_PRODUCT_RECOMMENDATION_TEST,
  SIGNUP_WITH_PRODUCT_PAGE_WITH_PRODUCT_RECOMMENDATION_TEST,
  CS_1_19_PHONE_SUPPORT_TEST,
  HEADER_NEW_PRODUCTS_OFFERING_TEST,
  LANGUAGE_PICKER_IN_HEADER_TEST,
  UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST,
  SHOW_SUCCESS_STORIES_IN_HEADER_TEST
};
