import { HP_SOURCE, PAGE_VIEW, TRACK_EVENT, STORIES_SOURCE } from "/constants/bigbrain-event-types";
import { truncateQueryParams } from "/utils/url";
import { isServerSide } from "utils/general";
import { CLUSTER_COOKIE } from "../constants/cookies";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID } from "/constants/cookies";
import { BIGBRAIN_TRACKING_URL } from "/constants/analytics";

const SET_UTM_CLUSTER_ID = "set_utm_cluster_id";
const SET_UTM_SUB_CLUSTER_ID = "set_utm_sub_cluster_id";
const SET_UTM_LOCALE_ID = "set_utm_locale_id";

const MILLISECONDS_IN_SECONDS = 1000;

const createEventData = (data = {}) => {
  const timestamp = parseInt(new Date().getTime() / MILLISECONDS_IN_SECONDS);
  const source = data.source || HP_SOURCE;

  return {
    timestamp,
    source,
    ...data
  };
};

const createLocalBigbrain = () => (originEvent, eventName, data = {}) => {
  if (originEvent === TRACK_EVENT) {
    const eventData = createEventData(data);
    const stringifyEventData = JSON.stringify(eventData);
    console.info([originEvent, eventName, stringifyEventData]);
  }
};

export const initBigBrainTracker = (isProduction) => {
  window.BigBrainQ = [];
  if (isProduction) {
    window.BigBrain = new BigBrainTracker({
      bigbrain_url: process.env.BIGBRAIN_ENDPOINT,
      send_immediately: true
    });
  } else {
    window.BigBrain = createLocalBigbrain();
  }
};

export const trackEvent = (eventName, data = {}) => {
  if (isServerSide()) { 
    console.log("bigbrain-service: trackEvent does not emit events from the server-side to BigBrain. If you must emit during a server call or render, use trackBigBrainEventOnServer().");
    return;
  }
  const eventData = createEventData(data);
  if (window.BigBrain) { 
    window.BigBrain(TRACK_EVENT, eventName, eventData);
  } else {
    console.log("Missing BigBrain in window");
  }
};

export const trackEventImmediately = async (name, data = {}) => {
  const eventData = createEventData(data);

  const metadata = {
    bbVisitorId: getCookie(BIG_BRAIN_VISITOR_ID),
    screen_height: screen.height,
    screen_width: screen.width,
    os_language: navigator.language,
    user_agent: navigator.userAgent
  };
  
  const mergedData = [{ ...eventData, ...metadata, name }];
  const body = JSON.stringify(mergedData);

  return fetch(BIGBRAIN_TRACKING_URL, {
      body,
      method: 'POST',
      keepalive: true,
      headers: { "Content-Type": "application/json" }
  });
}

export const setUtmClusterId = (clusterId) => {
  if (window.BigBrain) {
    window.BigBrain(SET_UTM_CLUSTER_ID, clusterId);
  }
};

export const setUtmSubClusterId = (subClusterId) => {
  return; // commented out until supported on bigbrain side
  if (window.BigBrain) {
    window.BigBrain(SET_UTM_SUB_CLUSTER_ID, subClusterId);
  }
};

export const setUtmLocaleId = (localeId) => {
  if (window.BigBrain) {
    window.BigBrain(SET_UTM_LOCALE_ID, localeId);
  }
};

export const trackCommunityEvent = (eventName, data = {}) => {
  data.source = STORIES_SOURCE;
  if (window.currentUser) {
    data.pulse_user_id = data.pulse_user_id || window.currentUser.mondayUserId;
    data.pulse_account_id = data.pulse_account_id || window.currentUser.mondayAccountId;
  } else if (window.mondayUserId) {
    data.pulse_user_id = data.pulse_user_id || window.mondayUserId;
  }

  const eventData = createEventData(data);
  if (window.BigBrain) {
    try {
      window.BigBrain(TRACK_EVENT, eventName, eventData);
    } catch (error) {
      console.log("error trackCommunityEvent ", error);
    }
  } else {
    console.log("Missing BigBrain in window");
  }
};

export const trackBigBrainPageView = (pathname = window.location.pathname, clusterId, subClusterId) => {
  pathname = truncateQueryParams(pathname);
  pathname = pathname[pathname.length - 1] === "/" ? pathname : pathname + "/";
  const data = {
    kind: pathname,
    info3: location.href,
    direct_object: window.visitGuid,
    info1: clusterId ? `${clusterId}${subClusterId ? "," + subClusterId : ""}` : ""
  };
  trackEvent(PAGE_VIEW, data);
};
