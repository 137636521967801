//export const FACEBOOK_PIXEL = "226122027576470";
//export const BING = "5103931";

//export const GOOGLE_ANALYTICS = "UA-27333868-10"; //USED ONLY IN GTM!
//export const GOOGLE_REMARKETING = "965311469"; //USED ONLY IN GTM!

//export const HOTJAR = "634726";
//export const HOTJAR_SNIPPET_VERSION = "5";

export const GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER = "GTM-WMDX8GX";
