// localization constants for server and client code
const ENGLISH_LOCALE_ID = "en";
const SPANISH_LOCALE_ID = "es";
const FRENCH_LOCALE_ID = "fr";
const GERMAN_LOCALE_ID = "de";
const PORTUGEESE_LOCALE_ID = "pt";
const ITALIAN_LOCALE_ID = "it";
const DUTCH_LOCALE_ID = "nl";
const RUSSIAN_LOCALE_ID = "ru";
const JAPANESE_LOCALE_ID = "ja";
const KOREAN_LOCALE_ID = "ko";
const TURKISH_LOCALE_ID = "tr";
const SWEDISH_LOCALE_ID = "sv";
const POLISH_LOCALE_ID = "pl";
const CHINESE_LOCALE_ID = "zh-tw";

const LANGUAGES_ROOT = "lang";

const SUPPORTED_LOCALE_IDS = [
  SPANISH_LOCALE_ID,
  FRENCH_LOCALE_ID,
  GERMAN_LOCALE_ID,
  PORTUGEESE_LOCALE_ID,
  RUSSIAN_LOCALE_ID,
  JAPANESE_LOCALE_ID,
  ITALIAN_LOCALE_ID,
  DUTCH_LOCALE_ID,
  KOREAN_LOCALE_ID,
  TURKISH_LOCALE_ID,
  SWEDISH_LOCALE_ID,
  POLISH_LOCALE_ID,
  CHINESE_LOCALE_ID
];

const getHomepageLocalizedRoutes = () => {
  return SUPPORTED_LOCALE_IDS.map((localeId) => `/${LANGUAGES_ROOT}/${localeId}/`);
};

const SUPPORTED_LOCALE_IDS_INCLUDING_ENGLISH = [ENGLISH_LOCALE_ID, ...SUPPORTED_LOCALE_IDS];

const UTM_LOCALE_COOKIE = "utm_locale_id";

const USER_SELECTED_LOCALE_ID_COOKIE = "user_selected_locale_id";

const LOCALIZED_PRICING_ROUTES = [
  "/pricing/",
  "/lang/es/pricing/",
  "/lang/fr/pricing/",
  "/lang/de/pricing/",
  "/lang/pt/pricing/",
  "/lang/ru/pricing/",
  "/lang/nl/pricing/",
  "/lang/ja/pricing/",
  "/lang/ko/pricing/",
  "/lang/it/pricing/",
  "/lang/tr/pricing/",
  "/lang/sv/pricing/",
  "/lang/pl/pricing/",
  "/lang/zh-tw/pricing/"
];

const COOKIES_POLICY_REQUIRED_COUNTRIES_ISO_CODES = [
  "IE",
  "NL",
  "FR",
  "DE",
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DK",
  "EE",
  "ES",
  "FI",
  "GB",
  "GR",
  "HR",
  "HU",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK"
];

const UNITED_STATES_COUNTRY_CODE = "US";

module.exports = {
  ENGLISH_LOCALE_ID,
  SPANISH_LOCALE_ID,
  FRENCH_LOCALE_ID,
  GERMAN_LOCALE_ID,
  PORTUGEESE_LOCALE_ID,
  ITALIAN_LOCALE_ID,
  DUTCH_LOCALE_ID,
  RUSSIAN_LOCALE_ID,
  JAPANESE_LOCALE_ID,
  KOREAN_LOCALE_ID,
  TURKISH_LOCALE_ID,
  SWEDISH_LOCALE_ID,
  POLISH_LOCALE_ID,
  CHINESE_LOCALE_ID,
  LANGUAGES_ROOT,
  SUPPORTED_LOCALE_IDS,
  SUPPORTED_LOCALE_IDS_INCLUDING_ENGLISH,
  UTM_LOCALE_COOKIE,
  USER_SELECTED_LOCALE_ID_COOKIE,
  LOCALIZED_PRICING_ROUTES,
  COOKIES_POLICY_REQUIRED_COUNTRIES_ISO_CODES,
  UNITED_STATES_COUNTRY_CODE,
  getHomepageLocalizedRoutes
};
