const isImageLoaded = (img) => {
  return img && img.complete;
};

// Scroll window to value with animation
const scrollTo = (toScrollTop, durationMs) => {
  var initialY = window.scrollY;
  var y = toScrollTop;
  var baseY = (initialY + y) * 0.5;
  var difference = initialY - baseY;
  var startTime = performance.now();

  function step() {
    var normalizedTime = (performance.now() - startTime) / durationMs;
    if (normalizedTime > 1) normalizedTime = 1;

    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
};

const isRunningInClient = () => {
  return !!(typeof window !== "undefined" && window.document && window.document.createElement);
};

const getScrollPercent = async () => {
  return new Promise((resolve, reject) => {
    try {
      requestAnimationFrame(() => {
        const p = document.body.parentNode;
        if (p.scrollHeight - p.clientHeight === 0) {
          return resolve(0);
        }
        const scrollRatio = ((document.body.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100;
        resolve(scrollRatio);
      });
    } catch (e) {
      reject();
    }
  });
};

const getDocumentFullHeight = () => {
  return new Promise((resolve, reject) => {
    try {
      requestAnimationFrame(() => {
        const fullDocumentHeight = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight
        );
        resolve(fullDocumentHeight);
      });
    } catch (e) {
      reject();
    }
  });
};

const getViewportHeight = () => {
  return new Promise((resolve, reject) => {
    try {
      requestAnimationFrame(() => {
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        resolve(viewportHeight);
      });
    } catch (e) {
      reject();
    }
  });
};

const getPassiveIfSupported = () => {
  let passiveIfSupported = false;

  try {
    const options = {
      get passive() {
        // This function will be called when the browser
        //   attempts to access the passive property.
        passiveIfSupported = true;
        return false;
      }
    };

    window.addEventListener("test", null, options);
    window.removeEventListener("test", null, options);
  } catch (err) {
    passiveIfSupported = false;
  }

  return passiveIfSupported;
};

const isNodeFullyInView = async (node) => {
  return new Promise((resolve, reject) => {
    try {
      requestAnimationFrame(() => {
        const rect = node.getBoundingClientRect();
        const inView =
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.left >= 0 &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        resolve(inView);
      });
    } catch (e) {
      reject();
    }
  });
};

module.exports = {
  isImageLoaded,
  scrollTo,
  isRunningInClient,
  getScrollPercent,
  getViewportHeight,
  getDocumentFullHeight,
  getPassiveIfSupported,
  isNodeFullyInView
};
