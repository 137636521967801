const MILLIS_IN_HOUR = 60 * 60 * 1000;
const STORIES_PARAGRAPH_MINIMUM_WORD_COUNT = 50;
const TEMPLATE_CREATE_DATE_REQUIRE_TOU_ACCEPT = new Date("2018-06-14");

const TemplateStatus = {
  PENDING: 0, // waiting approval
  APPROVED: 1, // online - approved by admin
  REJECTED: 2, // changes required - not online
  APPROVED_PAID: 3, // online - approved by admin + paid to user
  PENDING_USER_TO_APPROVE: 4, // Waiting for user to approve the board
  APPROVED_BY_USER: 5, // online - After user approved
  REJECTED_BY_USER: 6, // After user rejects
  DUPLICATED: 7, // Duplicated for A/B Tests
  DELETED: 9
};

const TemplateRejectReason = {
  NO_REASON: "None",
  BAD_SELFIE: "Bad Seflie",
  BAD_TEXT: "Bad Text",
  BAD_SELFIE_AND_TEXT: "Bad Selfie and Text"
};

const TagsCategory = {
  TOOLS: 1,
  INDUSTRY: 2,
  TEAM_SIZE: 3,
  BOARD_USAGE: 4,
  CATEGORIES: 5
};

const TemplateImageType = {
  SELFIE: "selfie",
  BOARD: "board"
};

const UserCampaignType = {
  STORIES_CAMPAIGN: "stories_campaign",
  STORIES_SOURCE: "stories_source",
  STORIES_MEDIUM: "stories_medium",
  STORIES_CONTENT: "stories_content",
  STORIES_CAMPAIGN_DATE: "stories_campaign_date",
  STORIES_SLUG: "stories_slug"
};

const TemplateTypeCookie = {
  STORIES_TEMPLATE_TYPE: "stories_template_type"
};

const StoriesCookies = {
  MONDAY_USER_ID: "stories_monday_user_id",
  MONDAY_ACCOUNT_SLUGS: "dapulseAccountSlugs"
};

const BoardLinkStatus = {
  LINKED: "Linked",
  NOT_LINKED: "Not Linked",
  MISMATCH: "Mismatch" // when user updated the board id after it was linked
};

const TemplateScoreType = {
  ADMIN_SCORE: "admin",
  LIKES_COUNT: "likes",
  VIEWS_COUNT: "views",
  USED_COUNT: "usage",
  POSTS_COUNT: "posts",
  FEATURED_SCORE: "featured",
  EDITOR_CHOICE: "editor_choice"
};

// Categories on stories main page - order matters for page
const StoriesMainPageCategories = {
  TRENDING: "Trending",
  MOST_USED: "Most used",
  EDITOR_CHOICE: "Editor's choice",
  // MOST_TALKED: "Most talked about",
  LATEST: "Latest"
};

const StoriesMainPageSortTypes = {
  LATEST: "Latest",
  MOST_USED: "Most used",
  TRENDING: "Trending",
  TEAM_SIZE: "Team size"
};

const StoriesMainPageCategoriesArr = Object.keys(StoriesMainPageCategories).map(key => StoriesMainPageCategories[key]);

const MAX_ADMIN_SCORE = 5;

const isTemplateApprovedStatus = status => {
  return (
    TemplateStatus.APPROVED == status ||
    TemplateStatus.APPROVED_PAID == status ||
    TemplateStatus.APPROVED_BY_USER == status
  );
};
const getTemplateStatusText = status => {
  const intStatus = parseInt(status);
  switch (intStatus) {
    case TemplateStatus.PENDING:
      return "Awaiting approval";
    case TemplateStatus.PENDING_USER_TO_APPROVE:
      return "Awaiting user approval";
    case TemplateStatus.APPROVED:
    case TemplateStatus.APPROVED_PAID:
    case TemplateStatus.APPROVED_BY_USER:
      return "Approved (online)";
    case TemplateStatus.REJECTED:
      return "Changes required";
    case TemplateStatus.REJECTED_BY_USER:
      return "Rejected by User";
    case TemplateStatus.DELETED:
      return "Deleted";
    default:
      return "";
  }
};

const getTemplateStatusClass = status => {
  const intStatus = parseInt(status);
  switch (intStatus) {
    case TemplateStatus.PENDING:
      return "pending";
    case TemplateStatus.PENDING_USER_TO_APPROVE:
      return "pending user";
    case TemplateStatus.APPROVED:
    case TemplateStatus.DUPLICATED:
      return "approved";
    case TemplateStatus.APPROVED_PAID:
      return "approved paid";
    case TemplateStatus.APPROVED_BY_USER:
      return "approved user";
    case TemplateStatus.REJECTED:
      return "rejected";
    case TemplateStatus.REJECTED_BY_USER:
      return "rejected user";
    case TemplateStatus.DELETED:
      return "deleted";
    default:
      return "";
  }
};

const getTagCategoryText = category => {
  const intCategory = parseInt(category);
  switch (intCategory) {
    case TagsCategory.TOOLS:
      return "Tools";
    case TagsCategory.INDUSTRY:
      return "Industry";
    case TagsCategory.TEAM_SIZE:
      return "Team Size";
    case TagsCategory.BOARD_USAGE:
      return "Board Usage";
    case TagsCategory.CATEGORIES:
      return "Categories";
    default:
      return "";
  }
};

module.exports = {
  MILLIS_IN_HOUR,
  STORIES_PARAGRAPH_MINIMUM_WORD_COUNT,
  TemplateStatus,
  TemplateImageType,
  UserCampaignType,
  BoardLinkStatus,
  isTemplateApprovedStatus,
  getTemplateStatusText,
  getTemplateStatusClass,
  TagsCategory,
  getTagCategoryText,
  TemplateScoreType,
  StoriesMainPageCategories,
  StoriesMainPageCategoriesArr,
  StoriesMainPageSortTypes,
  MAX_ADMIN_SCORE,
  TEMPLATE_CREATE_DATE_REQUIRE_TOU_ACCEPT,
  TemplateTypeCookie,
  StoriesCookies,
  TemplateRejectReason
};
