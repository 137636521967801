import * as Sentry from "@sentry/nextjs";

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return null;

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
};

export const hexToRgba = (hex, alpha) => {
  let res = "";
  try {
    const { r, g, b } = hexToRgb(hex);
    res = `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureMessage("Faild to convert hex color to rgba");
  }
  return res;
};

export const isServerSide = () => {
  if (!process.browser) return true;
};
